import React, { ChangeEvent, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button, TextInput } from '@mantine/core';
import { ArrowLeftWhite, SearchIcon } from '@/shared/assets/svg';
import { Breadcrumbs } from '@/shared/ui-kit';
import { Path, PathLabels } from '@/shared/constants/links';
import styles from './404.module.scss';

const breadCrumbsItems = [
  { title: PathLabels.main, href: Path.main },
  { title: '404', href: '/404' },
];

export default function NotFound() {
  const [searchValue, setSearchValue] = useState('');
  const router = useRouter();

  const handleGoBack = () => {
    router.back();
  };

  const onChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      router.push(`${Path.faq.searchResults}?search=${searchValue}`);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Breadcrumbs list={breadCrumbsItems} />
      <div className={styles.content}>
        <div className={styles.leftBlock}>
          <div className={styles.lContent}>
            <div className={styles.title}>Страница не найдена</div>
            <div className={styles.text}>
              Возможно, вы указали неправильный адрес или страница, на которую вы хотели зайти, устарела и была удалена.
            </div>
            <div className={styles.form}>
              <Button
                classNames={{ root: styles.button, inner: styles.buttonInner }}
                size='md'
                leftIcon={<ArrowLeftWhite />}
                onClick={handleGoBack}
              >
                Назад
              </Button>
              <TextInput
                classNames={{ input: styles.input, root: styles.inputWrapper }}
                size='md'
                placeholder='Что вы хотите найти?'
                icon={<SearchIcon />}
                value={searchValue}
                onChange={onChangeSearchValue}
                onKeyUp={handleKeyUp}
              />
            </div>
          </div>
        </div>
        <div className={styles.rightBlock}>
          <Image className={styles.mainImage} src='/assets/img/404.png' alt='Not found' width={550} height={550} />
        </div>
      </div>
    </div>
  );
}
